// import google font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* reset styles */
* {
    color: inherit;
    margin: 0;
  }
  
body {
  position: relative;
  overflow-x: hidden;
}

ul {
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

hr {
  width: 90%;
  border: 0;
  border-top: 1px solid #BFBFBF;
  z-index: 999;
}

img {
  max-width: 100%;
  height: 100%;
}