.background-image {
     display: flex;
     width: 100%;
     height: 100vh;
     background-image: url('../../images/bg_right.jpeg');
     background-size: cover;
     background-position: center center;

     @include md-max {
          background-image: url('../../images/bg_none.jpeg');
     }
}

.login {
     display: flex;
     align-items: center;

     .verification {
          display: flex;
          margin-top: -5%;
          text-align: center;
          background: #FFFFFF;
          border: 10px solid #000000;
          box-shadow: 0px 4px 20px 15px rgba(0, 0, 0, 0.763);
          border-radius: 10px;

          @include md {
               margin-left: 17%;
          }
     }

     .notice {

          padding-left: 2%;
          padding-right: 2%;

          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: min(10vw, 48px);
          line-height: min(12vw, 65px);

          color: #000000;
     }

     .text {
          padding-left: 12%;
          padding-right: 12%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: min(4vw, 26px);
          line-height: min(5vw, 28px);
          
          color: #000000;
     }

     .mail {
          display: flex;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0;
          padding-left: 0%;
          width: 80%;

          font-family: 'Ubuntu';
          font-style: normal;
          font-weight: 400;
          font-size: min(5vw, 24px);
          line-height: min(6vw, 28px);
          text-align: center;
          /* identical to box height */
          
          color: #000000;

          @include sm-max {
               width: 90%;
          }
     }

     .resend {
          display: flex;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          width: 38%;
          padding: 2%;

          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: min(4vw, 16px);
          line-height: min(5.5vw, 22px);
          /* identical to box height */

          letter-spacing: 0.315em;
          text-decoration-line: underline;

          color: #FFFFFF;

          background: #000000;
          border-style: none;
          border-radius: 10px;
          box-shadow: 1px 3px 5px  #000000;

          &:hover {
               cursor: pointer;
               background: #474646;
          }

          &:disabled {
               background: #474646;
               &:hover {
                    cursor:default;
               }
          }

          @include sm-max {
               width: 50%;
          }
     }

     .timer {
          margin-top: 2%;

          font-family: 'Ubuntu';
          font-style: normal;
          font-weight: 600;
          font-size: min(4vw, 16px);
          line-height: min(3.5vw, 14px);
          text-align: center;

          color: #000000;
     }

     .pin {
          display: flex;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0;
          width: 60%;

          font-family: 'Ubuntu';
          font-style: normal;
          font-weight: 400;
          font-size: min(7vw, 32px);
          line-height: min(9vw, 36px);
          text-align: center;
          letter-spacing: 0.39em;
          
          color: #000000;

          @include sm-max {
               width: 70%;
          }
     }

     .verify {
          display: flex;
          justify-content: center;

          margin-left: auto;
          margin-right: auto;
          width: 35%;
          padding: 2%;

          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: min(4.5vw, 18px);
          line-height: min(6.5vw, 26px);
          /* identical to box height */

          letter-spacing: 0.315em;
          text-decoration-line: underline;

          color: #000000;

          background: #FFDD00;
          border-style: none;
          border-radius: 10px;
          box-shadow: 1px 3px 5px  #000000;

          &:hover {
               cursor: pointer;
               background: #f7e677;
          }

          @include sm-max {
               width: 50%;
               padding-top: 3%;
               padding-bottom: 3%;
          }
     }

     @include sm-max {
          justify-content: center;
     }
}

.cookie-container {
     display: flex;
     flex-flow: row wrap;
     height: 8vh;
     padding-top: 1vh; 
     background: #000000 !important;

     @include xs-max {
          align-items: center;
          align-content: center;
          justify-content: center;
          text-align: center;
          height: auto;
     }
}

.cookie-button {
     margin-right:2vw !important;
     color:#000000 !important;
     background: #ffdd00 !important; 
     width: 110px;
     font-size: 22px !important;
     border-radius: 10px !important; 
     @include xs-max {
          margin-right: 15px !important;
     }
}

.cookie-content{
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 400;
     font-size: min(4vw, 26px);
     line-height: min(5vw, 18px);
     margin-left:2vw !important;

     span {
          font-size: min(4vw, 18px);
     }

     @include xs-max {
          margin-bottom: 0 !important;
     }
}

.cookie-button-wrapper{
     @include xs-max {
          margin-left: auto;
          margin-right: auto;
          padding-top: auto;
     }
}