.navbar {
     box-sizing: border-box-;
     background-color: black;
     height: min(16vw, 75px);
     z-index: 999;

     @include sm-max {
          padding: 0%;
     }
}

.logo {
     width: min(44vw, 200px);
     height: auto;

     padding: 10px;

     @include sm-max {
          margin-left: auto;
          margin-right: auto;
     }
}

.image {
     @include md {
          width: 800px; 
          min-height: 800px; 
          height: 100vh;
          object-fit: cover;
     }

     @include sm-max {
          width: 100%; 
          height: 220px;
          object-fit: cover; 
          object-position: 0 15%;
     }
}