@use 'sass:math';

$utilities: (
  "padding": (
    "prefix": "p",
    "values": (
      "0": 0,
      "1": $base-padding,
      "2": $base-padding * 2,
      "3": $base-padding * 4,
      "4": $base-padding * 6,
      "5": $base-padding * 8,
      "6": $base-padding * 10
    ),
  ),
  "padding-left": (
    "prefix": "pl",
    "values": (
      "0": 0,
      "1": $base-padding,
      "2": $base-padding * 2,
      "3": $base-padding * 4,
      "4": $base-padding * 6,
      "5": $base-padding * 8,
      "6": $base-padding * 10
    ),
  ),
  "padding-right": (
    "prefix": "pr",
    "values": (
      "0": 0,
      "1": $base-padding,
      "2": $base-padding * 2,
      "3": $base-padding * 4,
      "4": $base-padding * 6,
      "5": $base-padding * 8,
      "6": $base-padding * 10
    ),
  ),
  "padding-top": (
    "prefix": "pt",
    "values": (
      "0": 0,
      "1": $base-padding,
      "2": $base-padding * 2,
      "3": $base-padding * 4,
      "4": $base-padding * 6,
      "5": $base-padding * 8,
      "6": $base-padding * 10
    ),
  ),
  "padding-bottom": (
    "prefix": "pb",
    "values": (
      "0": 0,
      "1": $base-padding,
      "2": $base-padding * 2,
      "3": $base-padding * 4,
      "4": $base-padding * 6,
      "5": $base-padding * 8,
      "6": $base-padding * 10
    ),
  ),
  "margin": (
    "prefix": "m",
    "values": (
      "0": 0,
      "1": $base-margin,
      "2": $base-margin * 2,
      "3": $base-margin * 4,
      "4": $base-margin * 6,
      "5": $base-margin * 8,
      "6": $base-padding * 10
    ),
  ),
  "margin-left": (
    "prefix": "ml",
    "values": (
      "0": 0,
      "1": $base-margin,
      "2": $base-margin * 2,
      "3": $base-margin * 4,
      "4": $base-margin * 6,
      "5": $base-margin * 8,
      "6": $base-margin * 10
    ),
  ),
  "margin-right": (
    "prefix": "mr",
    "values": (
      "0": 0,
      "1": $base-margin,
      "2": $base-margin * 2,
      "3": $base-margin * 4,
      "4": $base-margin * 6,
      "5": $base-margin * 8,
      "6": $base-margin * 10
    ),
  ),
  "margin-top": (
    "prefix": "mt",
    "values": (
      "0": 0,
      "1": $base-margin,
      "2": $base-margin * 2,
      "3": $base-margin * 4,
      "4": $base-margin * 6,
      "5": $base-margin * 8,
      "6": $base-margin * 10
    ),
  ),
  "margin-bottom": (
    "prefix": "mb",
    "values": (
      "0": 0,
      "1": $base-margin,
      "2": $base-margin * 2,
      "3": $base-margin * 4,
      "4": $base-margin * 6,
      "5": $base-margin * 8,
      "6": $base-margin * 10
    ),
  ),
  "opacity": (
    "prefix": "o",
    "values": (
      "10": 0.1,
      "20": 0.2,
      "30": 0.3,
      "40": 0.4,
      "50": 0.5,
      "60": 0.6,
      "70": 0.7,
      "80": 0.8,
      "90": 0.9,
      "100": 1,
    ),
  ),
  "border-radius": (
    "prefix": "br",
    "values": (
      "default": $base-border-radius,
      "none": 0,
      "xs": math.div($base-border-radius, 4),
      "sm": math.div($base-border-radius, 2),
      "lg": $base-border-radius * 2,
      "full": 50%,
    )
  ),
  "display": (
    "prefix": "display",
    "values": (
      "n": none,
      "b": block,
      "f": flex,
      "i": inline,
      "i-b": inline-block
    )
  ),
  "font-size": (
    "prefix": "font",
    "values": (
      "sm": $font-size-sm,
      "md": $base-font-size,
      "lg": $font-size-lg,
      "xl": $font-size-xl,
      "xxl": $font-size-xxl
    )
  )
);

// generate utility classes
@each $property, $map in $utilities {
  $prefix: map-get($map, "prefix");
  $values: map-get($map, "values");

  @each $k, $v in $values {
    @if ($k == "default") {
        .#{$prefix} {
            #{$property}: $v;
        }
    } @else {
        .#{$prefix}-#{$k}{
            #{$property}: $v;
        }
    }
  }
}

@include xs {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-xs {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-xs-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include sm {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-sm {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-sm-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include md {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-md {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-md-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include lg {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-lg {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-lg-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include xl {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-xl {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-xl-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}


// Interval breakpoints
/// xs through lg intervals
@include xs-to-sm {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-x2s {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-x2s-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include xs-to-md {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-x2m {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-x2m-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include xs-to-lg {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-x2l {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-x2l-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include xs-to-xl {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-x2x {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-x2x-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

/// sm through lx intervals
@include sm-to-md {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-s2m {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-s2m-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include sm-to-lg {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-s2l {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-s2l-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include sm-to-xl {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-s2x {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-s2x-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

/// md through lx intervals
@include md-to-lg {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-m2l {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-m2l-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include md-to-xl {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-m2x {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-m2x-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

/// lg through lx intervals
@include lg-to-xl {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-l2x {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-l2x-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include xs-max {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-xsm {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-xsm-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include sm-max {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-smm {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-smm-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include md-max {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-mdm {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-mdm-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}

@include lg-max {
  @each $property, $map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $k, $v in $values {
      @if ($k == "default") {
          .#{$prefix}-lgm {
              #{$property}: $v;
          }
      } @else {
          .#{$prefix}-lgm-#{$k}{
              #{$property}: $v;
          }
      }
    }
  }
}