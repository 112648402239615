// Variables
$center-margin: 9vw;
$input-width-md: 95%;
$input-width-sm: 100%;

// Header
.content {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;

     padding-left: $center-margin;
     padding-right: $center-margin;
     
     @include md {
          overflow-y: auto;                  /* new */
          height: 92vh;
          max-height: 100vh;
     }

     @include lg-max {
          padding-left: $center-margin - 6;
          padding-right: $center-margin - 6;
     }
}

.heading {
     width: 100%;
}

.title {
     display: flex;
     width: 100%;
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 400;
     font-size: min(9vw, 48px);
     line-height: min(11vw, 65px);
     
     color: #000000;

     @include sm-max {
          margin-top: 2vh;
          margin-bottom: 2vh;
          text-align: center;
     }
}

.help {
     display: flex;
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 400;
     font-size: min(3.2vw, 18px);
     line-height: min(4vw ,20px);
     /* identical to box height */
     
     color: #000000;

     @include sm-max {
          margin-bottom: 2vh;
          justify-content: center;
          text-align: center;
     }
}

.link {
     display: flex;
     font-family: 'Montserrat';
     font-style: italic;
     font-weight: 700;
     font-size: min(3.2vw, 16px);
     line-height: min(4vw ,20px);
     /* identical to box height */

     text-decoration-line: underline;

     color: #FFDD00;
}

.figura-top {
     width: 42px;
     height: 8px;
     margin-top: 2%;
     background: #FFDD00;

     @include sm-max {
          margin-bottom: 1vh;
          margin-left: auto;
          margin-right: auto;
     }
}

// Content

.forms {
     display: flex;
     flex-wrap: wrap;
     align-content: flex-start;
     width: 100%;
}

.label {
     display:inline-flex;

     width: 100%;
     
     margin-top: 3%;

     font-family: 'Source Sans Pro';
     font-style: normal;
     font-weight: 700;
     font-size: min(4.6vw, 20px);
     line-height: min(5vw ,25px);

     color: #000000;
}

.email {
     display: flex;
     box-sizing: border-box;

     width: 97.5%;
     height: 40px;
     margin-top: 1%;
     margin-bottom: 1%;
     padding-left: 2%;

     background: rgba(217, 217, 217, 0.07);

     /* Placeholder */
     border: 1px solid #999999;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 10px;

     // Text 
     font-family: 'Ubuntu';
     font-style: normal;
     font-weight: 400;
     font-size: min(3.6vw, 18px);
     line-height: min(4.6vw ,23px);
     /* identical to box height */

     color: rgba(0, 0, 0, 0.49);

     &:focus {
          outline: none !important;
          border: 2px solid var(--select-focus);
     }

     @include sm-max {
          width: $input-width-sm;
     }
}

.input {
     display: flex;
     box-sizing: border-box;

     width: $input-width-md;
     height: 40px;
     margin-top: 1%;
     margin-bottom: 3%;
     padding-left: 4%;

     /* Placeholder */

     border: 2px solid #999999;
     //box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 10px;

     // Text 
     font-family: 'Ubuntu';
     font-style: normal;
     font-weight: 400;
     font-size: min(3.2vw, 19px);
     line-height: min(4.6vw ,23px);
     
     /* identical to box height */

     color: #000000;

     &:focus {
          outline: none !important;
          border: 2px solid var(--select-focus);
     }

     @include sm-max {
          padding-left: 2%;
          width: $input-width-sm;
     }
}


.prefilled {
     display: flex;
     box-sizing: border-box;

     width: $input-width-md;
     height: 40px;
     margin-top: 1%;
     margin-bottom: 1%;
     padding-left: 4%;

     background: rgba(217, 217, 217, 0.07);

     /* Placeholder */
     border: 1px solid #999999;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 10px;

     // Text 
     font-family: 'Ubuntu';
     font-style: normal;
     font-weight: 400;
     font-size: min(3.6vw, 18px);
     line-height: min(4.6vw ,23px);
     /* identical to box height */

     color: rgba(0, 0, 0, 0.49);

     &:focus {
          outline: none !important;
          border: 2px solid var(--select-focus);
     }

     @include sm-max {
          padding-left: 2%;
          width: $input-width-sm;
     }
}

select {
     // A reset of styles, including removing the default dropdown arrow
     appearance: none;
     // Additional resets for further consistency
     background-color: transparent;
     border: none;
     border-radius: 8px;
     padding: 0 0 0 0;
     padding-left: 3%;
     margin: 0;
     height: 37px;
     font-family: inherit;
     font-size: inherit;
     cursor: inherit;
     line-height: inherit;

     &:invalid {
          color: gray;
     }

     @include sm-max {
          padding-left: 1.3%;
     }
}

select::-ms-expand {
     display: none;
}

:root {
     --select-border: #777;
     --select-focus: black;
     --select-arrow: var(--select-border);
}

.select {
     box-sizing: border-box;

     width: $input-width-md;
     margin-top: 1%;

     /* Placeholder */

     border: 2px solid #999999;
     //box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 10px;

     // Text 
     font-family: 'Ubuntu';
     font-family: 'Ubuntu';
     font-style: normal;
     font-weight: 400;
     font-size: min(3.2vw, 18px);

     cursor: pointer;
     background-color: #fff;
     background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
     display: grid;
     grid-template-areas: "select";
     align-items: center;
     position: relative;

     @include sm-max {
          width: $input-width-sm;
     }
}

.select::after {
     content: "";
     width: 0.8em;
     height: 0.5em;
     margin-right: .5em;
     background-color: var(--select-arrow);
     clip-path: polygon(100% 0%, 0 0%, 50% 100%);
     justify-self: end;
}

select,
.select:after {
     grid-area: select;
}

.select:focus + .focus {
     position: absolute;
     top: -1px;
     left: -1px;
     right: -1px;
     bottom: -1px;
     border: 2px solid var(--select-focus);
}

.info {
     font-family: 'Ubuntu';
     font-style: normal;
     font-weight: 300;
     font-size: min(2.4vw, 12px);
     line-height: min(2.8vw ,14px);
     /* identical to box height */

     color: #8C8C8C;
}

.error {
     margin-top: 1%;
     color: #f84c4c;
     text-align: center;
     font-size: min(4vw, 20px);
     line-height: min(5vw ,25px);
}

.error-login {
     width: 100%;
     margin-top: 1%;
     margin-left: auto;
     margin-right: auto;
     color: #f84c4c;
     text-align: center;
     font-size: min(4vw, 20px);
     line-height: min(5vw ,25px);
}

.captcha {
     display: flex;
}

.aviso {
     margin-bottom: 1%;

     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 400;

     font-size: min(3vw, 15px);
     line-height: min(3.6vw ,18px);
     
     color: #000000;
     display: grid;
     grid-template-columns: 1em auto;
     gap: 0.5em;
     
     input[type="checkbox"] {
          /* Add if not using autoprefixer */
          -webkit-appearance: none;
          appearance: none;
          /* For iOS < 15 to remove gradient background */
          background-color: #fff;
          /* Not removed via appearance */
          margin: 0;
          font: inherit;
          color: currentColor;
          width: 1.15em;
          height: 1.15em;
          border: 0.15em solid currentColor;
          border-radius: 0.2em;

          display: grid;
          place-content: center;

          &::before {
               content: "";
               width: 0.65em;
               height: 0.65em;
               transform: scale(0);
               transition: 120ms transform ease-in-out;
               box-shadow: inset 1em 1em #FFDD00;
               background-color: CanvasText;
          }

          &:checked::before {
               transform: scale(1);
          }
     }

     span {
          margin-left: .5%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: min(3vw, 15px);
          line-height: min(3.6vw ,18px);

          color: #000000;
     }
}

button[type="submit"] {
     
     position: relative;
     background: #FFDD00;
     color: black;
     text-transform: uppercase;

     border: none;
     margin-top: 20px;
     padding: 20px;

     font-family: 'Roboto';
     font-style: normal;
     font-weight: 700;
     font-size: min(3.2vw, 20px);
     line-height: min(4.6vw ,23px);

     display: block;
     appearance: none;
     border-radius: 10px;
     margin-left: auto;
     margin-right: auto;
     width: 50%;
     font-weight: 400;
     letter-spacing: 0.3rem;
     transition: 0.3s all;
     text-decoration-line: underline;
     
     &:hover {
          background: #ffec6e;
          cursor: pointer;
     }

     &:disabled{
          background: #f3eab4;
          cursor: default;
     }
}

.hovertext {
     position: relative;
     border-bottom: 1px dotted black;

     &:before {
          content: attr(data-hover);
          visibility: hidden;
          opacity: 0;
          width: 350px;
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          background-color: #ffdd00;
          color: #000;
          text-align: center;
          border-radius: 5px;
          padding: 20px 10px;
          transition: opacity 0.3s ease-in-out;
          
          position: absolute;
          z-index: 1;
          left: 0;
          top: 110%;
     }
     
     &:hover:before {
          opacity: 1;
          visibility: visible;
     }
}

.button-forms-spinner {
     display: flex;
     margin-left: auto;
     margin-right: auto;
     width: 12px;
     height: 12px;
     border: 5px solid #000;
     border-top: 5px solid #fd0;
     border-radius: 50%;
     animation: buttonSpinner 1s linear infinite;
}