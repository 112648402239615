@use "sass:math";

$grid-columns: 12;
$grid-gaps: (
    "0": 0,
    "1": 10px,    
    "2": 20px,
    "3": 30px,
);
$layout-values: flex-start, flex-end, center, space-between, space-around;

// Base Layout Classes
.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.row {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

// Grid Gaps
@each $key, $val in $grid-gaps {
    .gap-#{$key} > * {
        padding: $val;
    }

    .gap-#{$key} {
        margin-left: -$val;
        margin-right: -$val;
    }}


// Justify Content Classes
@each $val in $layout-values {
    .justify-#{$val} {
        justify-content: $val;
    }
}

// Column Classes
@include xs {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-xs {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: math.div($i * 100%, $grid-columns);
        }
    }

    .col-5-12-xs {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: 20%;
    }
}

@include sm {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-sm {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: math.div($i * 100%, $grid-columns);
        }
    }

    .col-5-12-sm {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: 20%;
    }
}

@include md {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-md {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: math.div($i * 100%, $grid-columns);
        }
    }

    .col-5-12-md {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: 20%;
    }
}

@include lg {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-lg {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: math.div($i * 100%, $grid-columns);
        }
    }

    .col-5-12-lg {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: 20%;
    }
}

@include xl {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-xl {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: math.div($i * 100%, $grid-columns);
        }
    }

    .col-5-12-xl {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: 20%;
    }
}
