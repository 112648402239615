
@mixin format {
     max-width: 100%;
     margin: 10px 0;
     padding: 0%;
     text-align: left;
}
.aviso-cookies {
     overflow-y: auto;                  /* new */
     height: 88vh;
     max-height: 100vh;
     padding-left: 10vw;
     padding-right: 10vw;

     .heading{
          @include format();
          font-family: 'Raleway', sans-serif;
          font-size: 26px;
          line-height: 28px;
          color: #797979;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;

          &::selection {
               background: #ffdd00;
          }
     }

     .texto {
          @include format();
          font-family: 'Raleway', sans-serif;
          font-size: 20px;
          line-height: 28px;
          color: #797979;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;

          &::selection {
               background: #ffdd00;
          }
     }
     .version {
          @include format();
          justify-content: center;
          text-align: center;
          width: 50%;
          font-family: 'Raleway', sans-serif;
          font-size: 16px;
          line-height: 28px;
          color: #797979;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
     }
}