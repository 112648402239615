@use 'sass:math';

@mixin badge($bg-color: $info) {
    border-radius: $base-border-radius * 4;
    background-color: $bg-color;
    padding: math.div($base-padding, 4) math.div($base-padding, 2);
    font-size: $font-size-sm;
    font-weight: normal;
}

.badge {
    @include badge;
}

@each $key, $val in $colors {
    .badge-#{$key} {
        @include badge($val);
    }
}