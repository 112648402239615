$breakpoints: (
    "xs": 0,
    "sm": 480px,
    "md": 720px,
    "lg": 960px,
    "xl": 1200px
);

// Normal breakpoints
@mixin xs {
    @media (min-width: map-get($breakpoints, "xs")){
        @content;
    }
}
@mixin sm {
    @media (min-width: map-get($breakpoints, "sm")){
        @content;
    }
}
@mixin md {
    @media (min-width: map-get($breakpoints, "md")){
        @content;
    }
}
@mixin lg {
    @media (min-width: map-get($breakpoints, "lg")){
        @content;
    }
}
@mixin xl {
    @media (min-width: map-get($breakpoints, "xl")){
        @content;
    }
}

// Interval breakpoints
/// xs through lg intervals
@mixin xs-to-sm {
    @media (min-width: map-get($breakpoints, "xs")) and (max-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin xs-to-md {
    @media (min-width: map-get($breakpoints, "xs")) and (max-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin xs-to-lg {
    @media (min-width: map-get($breakpoints, "xs")) and (max-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin xs-to-xl {
    @media (min-width: map-get($breakpoints, "xs")) and (max-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

/// sm through lx intervals
@mixin sm-to-md {
    @media (min-width: map-get($breakpoints, "sm")) and (max-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin sm-to-lg {
    @media (min-width: map-get($breakpoints, "sm")) and (max-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin sm-to-xl {
    @media (min-width: map-get($breakpoints, "sm")) and (max-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

/// md through lx intervals
@mixin md-to-lg {
    @media (min-width: map-get($breakpoints, "md")) and (max-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin md-to-xl {
    @media (min-width: map-get($breakpoints, "md")) and (max-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

/// lg through lx intervals
@mixin lg-to-xl {
    @media (min-width: map-get($breakpoints, "lg")) and (max-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

// Maximum breakpoints
@mixin xs-max {
    @media (max-width: map-get($breakpoints, "sm")){
        @content;
    }
}
@mixin sm-max {
    @media (max-width: map-get($breakpoints, "md")){
        @content;
    }
}
@mixin md-max {
    @media (max-width: map-get($breakpoints, "lg")){
        @content;
    }
}
@mixin lg-max {
    @media (max-width: map-get($breakpoints, "xl")){
        @content;
    }
}


@mixin breakpoint($bp: 0) {
    @media (min-width: $bp) {
        @content;
    }
}