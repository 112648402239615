@each $key, $val in $colors {
    .text-#{$key} {
        color: $val;
    }

    .text-hover-#{$key} {
        &:hover {
            color: $val;
        }
    }

    .bg-#{$key} {
        background-color: $val;
    }

    @if ($val != black and $val != white) {
        // Color Variation
        @for $i from 1 through  9 {
            // Light Variation
            .text-#{$key}-light-#{$i} {
                color: mix(white, $val, $i * 10);
            }

            .text-hover-#{$key}-light-#{$i} {
                &:hover {
                    color: mix(white, $val, $i * 10);
                }
            }        

            .bg-#{$key}-light-#{$i} {
                background-color: mix(white, $val, $i * 10);
            }
        }

        @for $i from 1 through 9 {
            // Dark Variation
            .text-#{$key}-dark-#{$i} {
                color: mix(black, $val, $i * 10);
            }

            .text-hover-#{$key}-dark-#{$i} {
                &:hover {
                    color: mix(black, $val, $i * 10);
                }
            }    

            .bg-#{$key}-dark-#{$i} {
                background-color: mix(black, $val, $i * 10);
            }
        }   
    }
}
