// Header

// Content

.description {
     margin-top: 4%;
     width:100%;     
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 400;
     font-size: min(4vw, 18px);
     line-height: min(4.6vw ,22px);

     text-align: center;

     color: #000000;

     .bolics {
          font-style: italic;
          font-weight: 700;
          text-decoration-line: underline;
     }

     @include sm-max {
     }
}

.campus {
     display: flex;
     text-align: center;

     .name {
          margin-bottom: 1%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: min(4.8vw, 24px);
          line-height: min(6vw ,30px);

          color: #000000;
     }

     .telefono {
          margin-bottom: 1%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: min(4vw, 20px);
          line-height: min(4.8vw ,24px);
          color: #000000;

          .bold {
               font-style: italic;
               font-weight: 600;
               text-decoration-line: underline;   
          }

          &::before {
               content: "\00B7";
          }
     }

     .horario {
          margin-bottom: 1%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: min(3.4vw, 15px);
          line-height: min(3.6vw ,18px);
          color: #000000;    
                
     }

     .dias {
          margin-bottom: 1%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: min(3.4vw, 15px);
          line-height: min(3.6vw ,18px);

          color: #000000;

          .bold {
               font-style: italic;
               font-weight: 700;
          }          
     }

     .correo {
          margin-bottom: 1%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: min(3.8vw, 20px);
          line-height: min(4.4vw ,24px);

          color: #000000;

          .bold {
               font-style: italic;
               font-weight: 600;
               text-decoration-line: underline;
          }
     }
}


// Footer
