// Footer
.footer {
     display: flex;
     flex-flow: wrap;
}

.end {
     margin-bottom: -8vh;

     @include xs-max {
          margin-top: 2vh;
          margin-bottom: 2vh;
     }
}

.soporte {
     display: flex;

     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 400;
     font-size: min(4vw, 15px);
     line-height: min(4.6vw ,18px);
     text-align: center;

     color: #000000;

     @include md{
          margin-top: 8%;
     }
}

.figura-bottom {
     display: flex;
     margin-left: auto;
     margin-right: auto;
     width: 42px;
     height: 8px;
     
     background: #000000;

     @include xs-max {
          margin-bottom: 2vh;
     }
}

.smail {
     &:link, &:visited {
          text-decoration: underline;
          color: #000000;
     }

     &:hover {
          color: #ffdd00;
     }
}