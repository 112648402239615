
@keyframes buttonSpinner {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
}

.button-spinner {
     width: 12px;
     height: 12px;
     border: 5px solid #fff;
     border-top: 5px solid #000;
     border-radius: 50%;
     animation: buttonSpinner 1.5s linear infinite;
}

$spinner: #FFDD00;
$background: white;
$size: 200px;

.spinner {
     position: absolute;
     top: calc(50% - #{$size});
     left: calc(50% - calc($size / 2));
     width: $size;
     height: $size;
     animation: spinner 0.75s linear infinite;

     .span {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
     }
     
     .spinner-inner-1 {
          background: linear-gradient(to right, rgba($spinner,0) 0%,rgba($spinner,0) 50%,$spinner 51%);
     }
     
     .spinner-inner-2 {
          background: linear-gradient(to top, rgba($background,0) 0%,$background 100%);
     }
     
     .spinner-inner-3 {
          $offset: $size * 0.1;
          top: $offset;
          left: $offset;
          width: $size - ($offset * 2);
          height: $size - ($offset * 2);
          background: $background;
     }
}

.spinner-text {
     position: absolute;
     top: calc(50% + calc($size/3));
     left: calc(48% - #{$size});

     .waiter {
          font-family: "Roboto";
          font-size: 36px;

          @include xs-max {
               font-size: 18px;
          }
     }

     @include md-max {
          left: calc(19%);
     }

     @include xs-max {
          left: calc(17%);
     }
}

@keyframes spinner {
     0% { transform: rotate(0deg); }
     100% { transform: rotate(360deg); }
}