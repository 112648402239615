.center {
     @include xs-max {
          margin-top: 3vh;
     }
}

.start {
     margin-top: 10vh;

     @include md-max {
          margin-top: 4vh;
     }

     @include sm-max {
          margin-top: 2vh;
          margin-bottom: 1vh;
     }
}

.result {
     display: flex;
     flex-flow: wrap;
     text-align: center;
}

.head {
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 400;
     font-size: min(9vw, 48px);
     line-height: min(11vw, 65px);

     color: #000000;
}

.center {
     display: flex;
     margin-left: auto;
     margin-right: auto;
     @include xs-max{
          margin-bottom: 3vh;
     }
}

.response {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 700;
     font-size: min(4.8vw, 36px);
     line-height: min(5.8vw, 29px);
     text-align: center;

     color: #000000;

     @include sm-max {
          margin-top: 2%;
     }
}

.padded {
     padding-left: 2vw;
     padding-right: 2vw;
}