
.unauthorized {
     display: flex;
     flex-flow: wrap;

     .state {
          display: flex;
          justify-content: center;
          text-align: center;

          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: min(46vw, 350px);
          line-height: min(50vw, 300px);

          color: #000000;
     }

     .tag {
          display: flex;
          justify-content: center;
          text-align: center;

          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: min(15vw, 100px);
          line-height: min(14vw, 136px);
          
          color: #000000;
     }

     .reason {
          display: flex;
          justify-content: center;
          text-align: center;

          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: min(6vw, 48px);
          line-height: min(8vw, 60px);
          text-decoration-line: underline;

          color: #000000;
     }

     .holder {
          box-sizing: border-box;
     }
}

.background {
     height: 95vh;
     background-color: #FFDD00;
}